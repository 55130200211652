* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
}

a {
  text-decoration: none;
}
svg {
  cursor: pointer;
}
